<template>
  <div class="error-page">
    <div class="err-404">
        <img :src="require('../assets/icons/404.svg')" alt="404" class="error-img" />
        <p class="error-text">{{$t('common.notFound')}}</p>
        <a class="back-home" href="/">{{$t('common.backToHome')}}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.error-page {
  .err-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    .error-img {
      width: 300px;
    }
    .error-text {
      font-size: 20px;
      line-height: 30px;
      margin-top: 20px;
    }
  }
  .back-home {
    font-size: 14px;
    margin-top: 30px;
    display: inline-block;
    &:hover{
      color: black;
    }
  }
}
</style>
